export const toDateOnly = function (ticks) {
  if (!ticks) {
    return '';
  }
  const date = new Date(ticks);
  if (date.getFullYear() < 1970) {
    return ''
  }
  let result = date.getFullYear() + '-';
  const month = date.getMonth() + 1;
  if (month < 10) {
    result = result + '0';
  }
  result = result + month + '-';
  if (date.getDate() < 10) {
    result = result + '0';
  }
  result = result + date.getDate();
  return result;
}

export const toDateTime = function (dateOnly, timeOnly) {
  const dateParts = dateOnly.split('-');
  const timeParts = timeOnly.split(':');
  const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], 0);
  return date;
}

export const toTimeOnly = function (ticks) {
  if (!ticks) {
    return '';
  }
  const date = new Date(ticks);
  let result = '';
  let hours = date.getHours();
  if (hours < 10) {
    result += '0';
  }
  result += hours + ":";
  let minutes = date.getMinutes();
  if (minutes < 10) {
    result += '0';
  }
  result += minutes;
  return result;
}