import { toast } from 'react-toastify';

const postSave = async function (url, data) {
  const id = toast.loading("Saving...")
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      'Accept': 'application/json',
      'Content-type': 'application/json'
    },
    credentials: 'include',
  });
  if (response.ok) {
    const data = await response.json();
    if (data) {
      toast.update(id, { render: "saved!", type: "success", isLoading: false, autoClose: 5000, closeButton: true, closeOnClick: true });
      return data;
    }
  }
  toast.update(id, { render: "there was a problem.", type: "error", isLoading: false, autoClose: 5000, closeButton: true, closeOnClick: true });
  return null;
}
export default postSave;