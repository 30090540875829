import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom'

export default function NavMenu({ url }) {
  return (
    <div>
      <header>
        <Navbar color="dark" className="fixed-top navbar-expand-sm navbar-toggleable-sm border-bottom mb-3 bg-dark" dark>
          <NavbarBrand>
            {/*<Link to={`/s/${url}`}>*/}
            <img className="logo-private" src="logo.svg" alt="logo" />
            <span className="logo-private-title">bakesel</span>
            <div className="d-inline">
              {' '}<span className="text-white small">{url}</span>
            </div>
            {/*</Link>*/}
          </NavbarBrand>
        </Navbar>
      </header>
    </div>
  );
}
