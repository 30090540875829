import { Button, Col, Input, Row } from 'reactstrap';
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import postSave from '../../services/PostSave';
import get from '../../services/Get';
import { isLoadingAtom } from "../../App";
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react';
import React from 'react'
import Select from 'react-select'

export default function UserAdd({ cancel }) {
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const schema = Yup.object().shape({
    firstname: Yup.string().required('required'),
    lastname: Yup.string().required('required'),
    email: Yup.string().email('that email address doesn\'t look right!').required('required').test("email check", "this email address has already been used", async value => {
      const response = await fetch('/api/validemail', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(value)
      });
      let data = await response.json();
      if (data) {
        return true;
      }
      return false;
    })
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        firstname: '',
        lastname: '',
        email: '',
      }}
      onSubmit={async (values) => {
        setIsLoading(true);
        await postSave("/api/user/add", {
          firstName: values.firstname,
          lastName: values.lastname,
          email: values.email,
          id: values.id
        });        cancel();
        setIsLoading(false);
      }}
    >
      <Form>
        <Row>
          <Col>
            <Field name="firstname" type="text" label="firstname" component={ReactstrapInput} />
            <Field name="id" type="hidden" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="lastname" type="text" label="lastname" component={ReactstrapInput} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="email" type="text" label="email" component={ReactstrapInput} />
          </Col>
        </Row>
        <Row>
          <Col auto>
            <Button type="submit" color="primary">
              add
            </Button>
            {' '}
            <Button type="button" color="secondary" onClick={cancel}>
              cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
}
