import { Field } from "formik";
import TextareaAutosize from 'react-textarea-autosize';

export default function FieldTextareaAutosize({ maxRows, minRows, label, ...props }) {
  return (
    <div className="mb-3">
      <label className="label-color form-label">{label}</label>
      <Field
        {...props}
      >
        {({ field }) =>
          <TextareaAutosize
            maxRows={maxRows}
            minRows={minRows}
            {...field}
            className="form-control" />
        }
      </Field>
    </div>
  );
}