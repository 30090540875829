import React, { useEffect, useState } from "react";
import AppPublic from './AppPublic';
import AppPrivate from './AppPrivate';
import Loading from './components/Loading'
import { ToastContainer } from 'react-toastify';
import post from "./services/Post";
import { atom, useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { Toaster } from 'react-hot-toast';
import AppGlobalAdmin from "./AppGlobalAdmin";
import { useLocation } from 'react-router-dom';
import AppShop from "./AppShop";

export const isLoadingAtom = atom(false);
export const isLoggedInAtom = atom(false);
export const userAtom = atomWithStorage('user', {});
export const titleAtom = atom();
export const companyAtom = atomWithStorage('company', {});

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [user,] = useAtom(userAtom);
  const [initial, setInitial] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkLoggedIn = async () => {
      setIsLoading(true);
      const data = await post("/api/isloggedin");
      setIsLoggedIn(data);
      setInitial(false);
      setIsLoading(false);
    };
    checkLoggedIn().catch(console.error);
  }, [setIsLoading, setIsLoggedIn])

  let loading = '';
  if (isLoading) {
    loading = <Loading />;
  }

  let isShop = false;
  if (location.pathname.startsWith('/s/')) {
    isShop = true;
  }

  const toastContainer = (
    <>
      <Toaster
        position="top-right"
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>);

  if (initial) {
    return <Loading />;
  }
  else if (isShop) {
    const url = location.pathname.replace('/s/', '');
    return <AppShop url={url} />
  }
  else if (isLoggedIn) {
    if (user.globalAdmin) {
      return (
        <>
          <AppGlobalAdmin />
          {loading}
          {toastContainer}
        </>
      );
    }
    else {
      return (
        <>
          <AppPrivate />
          {loading}
          {toastContainer}
        </>
      );
    }
  }
  else {
    return (
      <>
        <AppPublic />
        {loading}
        {toastContainer}
      </>
    );
  }
}
