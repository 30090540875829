import { Navigate, Route, Routes } from "react-router-dom";
import { Container } from 'reactstrap';
import { atom } from 'jotai'
import NavMenu from "./components/private/NavMenu";
import Home from './components/GlobalAdmin/Home'
import Profile from "./components/private/Profile";
import Password from "./components/private/Password";
import User from "./components/GlobalAdmin/User";

export const historyAtom = atom(false);
export const historyTitleAtom = atom('');

export default function AppGlobalAdmin() {

  const routes = (
    <Routes>
      <Route path="/password" element={<Password />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/user" element={<User />} />
      <Route path="/home" element={<Home />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );

  return (
    <div>
      <NavMenu />
      <Container fluid className="container-private">
        {routes}
      </Container>
      {/*<History />*/}
    </div>
  );
}