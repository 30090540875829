import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Field, Form, Formik } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import { useAtom } from 'jotai';
import { isLoadingAtom } from "../../App";

export default function Create() {
  const [isSent, setIsSent] = useState(false);
  const [, setIsError] = useState(false);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const CreateSchema = Yup.object().shape({
    email: Yup.string().email('that email address doesn\'t look right!').required('required').test("email check", "this email address has already been used", async value => {
      const response = await fetch('/api/validemail', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(value)
      });
      let data = await response.json();
      if (data) {
        return true;
      }
      return false;
    }),
    password: Yup.string().min(8, 'your password must be at least eight characters').required('required')
    //passwordConfirmation: Yup.string()
    //  .oneOf([Yup.ref('password'), null], 'The passwords must match.').required('Required')
  });

  async function create(values) {
    setIsLoading(true);
    const response = await fetch("/api/create", {
      method: "POST",
      body: JSON.stringify({ email: values.email, password: values.password }),
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      }
    });
    if (response.ok) {
      const data = await response.json();
      if (data) {
        setIsError(false);
        setIsSent(true);
      }
      else {
        setIsError(true);
      }
    }
    else {
      setIsError(true);
    }
    setIsLoading(false);
  }

  if (isSent) {
    return (
      <Card>
        <CardHeader>
          <h3>check your email</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              we sent you an email with instructions to verfiy your account
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="text-center">
                <Link to="/" className="link-button">
                  return to log in
                </Link>
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
  else {
    return (
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={CreateSchema}
        onSubmit={async (values) => {
          await create(values);
        }}
      >
        <Form>
          <Card>
            <CardHeader>
              <h3>create a new account</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Field name="email" type="email" label="email" component={ReactstrapInput} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field name="password" type="password" label="password" component={ReactstrapInput} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" color="primary" size="lg" block className="login-button">
                    next
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p className="text-center">
                    <Link to="/" className="link-button">
                      return to log in
                    </Link>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Formik>
    );
  }
}
