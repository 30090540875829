import { X } from 'react-bootstrap-icons';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import ItemImage from '../ItemImage';

export default function BakeEvent({ event, cancel }) {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col className="col-auto">
            <div className="container-img-item2">
              <ItemImage item={event} />
            </div>
          </Col>
          <Col>
            <Button
              className="icon-cancel"
              onClick={cancel}
              aria-label="close event"
              title="close event"
            ><X /></Button>
            <CardTitle tag="h5" className={event.disabled ? "text-muted" : ""}>
              <div>{new Date(event.startTicks).toLocaleDateString('en-US')}</div>
              <div>{new Date(event.startTicks).toLocaleTimeString('en-US')}</div>
              <div>{event.name}</div>
            </CardTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardText className='pre-wrap'>
              {event.description}
            </CardText>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
