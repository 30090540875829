import { useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { companyAtom, isLoadingAtom } from "../../App";
import get from "../../services/Get";
import post from '../../services/Post';
import { useAtom } from 'jotai';
import BakeEventEdit from './BakeEventEdit';
import Loading from '../Loading'
import ItemImage from "../ItemImage";

export default function BakeEvents() {
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [company,] = useAtom(companyAtom);
  const [options, setOptions] = useState([{}]);
  const [bakeEvent, setBakeEvent] = useState(null);
  const [initial, setInitial] = useState(true);

  const getBakeEvent = async (id) => {
    if (id) {
      const db = await get(`/api/bakeevent/${id}`);
      setBakeEvent(db);
    }
  };

  async function doAdd() {
    const be = await post(`/api/bakeevent/add/`, { companyId: company.id });
    let os = options;
    os.push(be);
    setOptions(os);
    setBakeEvent(be);
  }

  function cancelEdit() {
    setBakeEvent(null);
    getOptions();
  }

  const handleChange = (selectedOption) => {
    setIsLoading(true);
    if (selectedOption) {
      getBakeEvent(selectedOption.id);
    }
    setIsLoading(false);
  };

  const getOptions = useCallback(async () => {
    if (company && company.id > 0) {
      const os = await get(`/api/bakeevent/list/${company.id}`);
      setOptions(os);
      setInitial(false);
    }
  }, [company]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  if (initial) {
    return <Loading />
  }
  else if (bakeEvent) {
    return <BakeEventEdit bakeEvent={bakeEvent} cancel={cancelEdit} />;
  }
  else if (options) {
    let events = <></>;
    if (options && options.length > 0) {
      events = options.map((item, index) => (
        <Row key={index}>
          <Col>
            <Card onClick={() => handleChange(item)} role="button">
              <CardBody className={item.disabled ? "bg-secondary opacity-25" : ""}>
                <Row>
                  <Col className="col-auto">
                    <div className="container-img-item2">
                      <ItemImage item={item} />
                    </div>
                  </Col>
                  <Col>
                    <CardTitle tag="h5" className={item.disabled ? "text-muted text-decoration-line-through" : ""}>
                      <div>{new Date(item.startTicks).toLocaleDateString('en-US')}</div>
                      <div>{new Date(item.startTicks).toLocaleTimeString('en-US')}</div>
                      <div>{item.name}</div>
                    </CardTitle>
                    <CardText className={item.disabled ? "text-muted text-decoration-line-through" : ""}>
                      {item.description}
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row >
      ));
    }
    return (
      <>
        <Row>
          <Col><h3>your events</h3></Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col className="col-auto">
            <Button onClick={doAdd} title="add a event">add a new event</Button>
          </Col>
        </Row>
        {events}
      </>
    );
  }
}
