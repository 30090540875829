import { Navigate, Route, Routes } from "react-router-dom";
import { Container } from 'reactstrap';
import NavMenu from "./components/private/NavMenu";
import Home from './components/private/Home'
import Profile from "./components/private/Profile";
import Password from "./components/private/Password";
import Company from "./components/private/Company";
import Products from "./components/private/Products";
import BakeEvents from "./components/private/BakeEvents";

export default function AppPrivate() {

  const routes = (
    <Routes>
      <Route path="/events" element={<BakeEvents />} />
      <Route path="/home" element={<Home />} />
      <Route path="/password" element={<Password />} />
      <Route path="/products" element={<Products />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/shop" element={<Company />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );

  return (
    <div>
      <NavMenu />
      <Container fluid className="container-private">        
        {routes}
      </Container>
    </div>
  );
}