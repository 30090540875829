import { Link } from "react-router-dom";
import React from "react";
import { Alert, Button, Col, Row } from 'reactstrap';
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import postSave from '../../services/PostSave';
import { isLoadingAtom } from "../../App";
import { useAtom } from 'jotai'

export default function Password() {
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const schema = Yup.object().shape({
    password: Yup.string().min(8, 'enter a new password with at least eight characters.').required('required')
  });

  return (
    <>
      <h3>change password</h3>
      <Formik
        enableReinitialize
        initialValues={{
          password: '',
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          setIsLoading(true);
          await postSave("/api/password/update", { password: values.password });
          setIsLoading(false);
        }}
      >
        <Form>
          <Row>
            <Col>
              <Alert color="primary">
                your new password must contain at least eight characters.
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col>
              <Field name="password" type="password" label="password" component={ReactstrapInput} />
            </Col>
          </Row>
          <Button type="submit" color="primary">
            save
          </Button>

          <hr />

          <p><Link to="/profile">view your profile</Link></p>
        </Form>
      </Formik>
    </>
  );
}
