import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import get from '../../services/Get';
import post from '../../services/Post';
import { companyAtom, isLoadingAtom } from "../../App";
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import ProductEdit from './ProductEdit';
import Loading from '../../components/Loading'
import ItemImage from '../ItemImage';

export default function Products() {
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [company,] = useAtom(companyAtom);
  const [options, setOptions] = useState([{}]);
  const [product, setProduct] = useState(null);
  const [initial, setInitial] = useState(true);

  //const getProduct = async (id) => {
  //  if (id) {
  //    const db = await get(`/api/product/${id}`);
  //    setProduct(db);
  //  }
  //};

  async function doAdd() {
    const p = await post(`/api/product/add/`, { companyId: company.id });
    let os = options;
    os.push(p);
    setOptions(os);
    setProduct(p);
  }
  function cancelEdit() {
    setProduct(null);
    getOptions();
  }

  const handleChange = (selectedOption) => {
    //setIsLoading(true);
    //if (selectedOption) {
    //  getProduct(selectedOption.id);
    //}
    setProduct(selectedOption);
    //setIsLoading(false);
  };

  const getOptions = useCallback(async () => {
    if (company && company.id > 0) {
      const os = await get(`/api/product/list/${company.id}`);
      setOptions(os);
      setInitial(false);
    }
  }, [company]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  if (initial) {
    return <Loading />
  }
  else if (product) {
    return <ProductEdit product={product} cancel={cancelEdit} setProduct={setProduct} />;
  }
  else if (options) {
    let products = <></>;
    if (options && options.length > 0) {
      products = options.map((item, index) => (
        <Row key={index}>
          <Col>
            <Card onClick={() => handleChange(item)} role="button">
              <CardBody className={item.deleted ? "bg-secondary opacity-25" : ""}>
                <Row>
                  <Col className="col-auto">
                    <div className="container-img-item2">
                      <ItemImage item={item} />
                    </div>
                  </Col>
                  <Col>
                    <CardTitle tag="h5" className={item.deleted ? "text-muted text-decoration-line-through" : ""}>
                      {item.name}
                    </CardTitle>
                    <CardText className={item.deleted ? "text-muted text-decoration-line-through" : ""}>
                      {item.description}
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row >
      ));
    }

    return (
      <>
        <Row>
          <Col><h3>your products</h3></Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col className="col-auto">
            <Button onClick={doAdd} title="add a product">add a new product</Button>
          </Col>
        </Row>
        {products}
      </>
    );
  }
}