import { Navigate, Route, Routes } from "react-router-dom";
import { Container } from 'reactstrap';
import Login from './components/public/Login'
import Confirm from './components/public/Confirm'
import Create from './components/public/Create'
import Forgot from "./components/public/Forgot";
import Reset from "./components/public/Reset";

export default function AppPublic({ handleLogin }) {

  const routes = (
    <Routes>
      <Route path="/confirm/:email/:code" element={<Confirm />} />
      <Route path="/create" element={<Create />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/reset/:email/:code" element={<Reset />} />      
      <Route path="/" element={<Login handleLogin={handleLogin} />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );

  return (
    <div className="public-container-wrapper">
      <Container className="d-md-flex align-items-center justify-content-center vh-100">
        <div>
          <h1 className="text-center">
            <img className="logo-public" src="logo.svg" alt="logo" />
            <span className="logo-1">bakel</span><span className="logo-2">sel</span>
          </h1>
          {routes}
        </div>
      </Container>
    </div>
  );
}