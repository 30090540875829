import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Button, Col, Row } from 'reactstrap';
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import get from '../../services/Get';
import postSave from '../../services/PostSave';
import { isLoadingAtom, userAtom } from "../../App";
import { useAtom } from 'jotai'

export default function Profile() {
  const [model, setModel] = useState({});
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [user, setUser] = useAtom(userAtom);

  const schema = Yup.object().shape({
    email: Yup.string().email('that email address doesn\'t look right!').required('required')
  });

  useEffect(() => {
    const getPreModel = async () => {
      setIsLoading(true);
      const preModel = await get("/api/profile");
      setUser(preModel);
      setModel({
        email: preModel.email ?? '',
        firstName: preModel.firstName ?? '',
        lastName: preModel.lastName ?? ''
      });
      setIsLoading(false);
    };
    getPreModel().catch(console.error);
  }, [])

  return (<>
    <Row>
      <Col><h3>your information</h3></Col>
    </Row>
    <Formik
      enableReinitialize
      initialValues={{
        email: model.email ?? '',
        firstName: model.firstName ?? '',
        lastName: model.lastName ?? ''
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        setIsLoading(true);
        await postSave("/api/profile", { email: values.email, firstName: values.firstName, lastName: values.lastName });
        user.email = values.email;
        user.firstName = values.firstName;
        user.lastName = values.lastName;
        setUser(user);
        setIsLoading(false);
      }}
    >
      <Form>
        <Row>
          <Col>
            <Field name="email" type="email" label="email" component={ReactstrapInput} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="firstName" type="text" label="first name" component={ReactstrapInput} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="lastName" type="text" label="last name" component={ReactstrapInput} />
          </Col>
        </Row>
        <Button type="submit" color="primary">
          save
        </Button>

        <hr />

        <p><Link to="/password">change your password</Link></p>

      </Form>
    </Formik>
  </>
  );
}
