export const ReactSelectStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data }) => {
    return {
      ...styles,
      color: data.deleted ? '#cccccc' : "",
      textDecoration: data.deleted ? "line-through" : ""
    };
  }
};
