import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Form, Formik } from "formik";
import post from '../../services/Post';
import { useAtom } from 'jotai';
import { isLoadingAtom } from "../../App";

export default function Confirm() {
  let { email, code } = useParams();
  const [isError, setIsError] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  useEffect(() => {
    const confirm = async () => {
      setIsLoading(true);
      const data = await post('/api/confirm', { code: code, email: email });
      if (data) {
        setIsError(false);
      }
      else {
        setIsError(true);
      }
      setIsLoading(false);
    };
    confirm().catch(console.error);
  }, [])

  if (isError) {
    return (
      <Formik
        onSubmit={async (values) => {
          setIsLoading(true);
          const data = await post('/api/create', { email: email });
          if (data) {
            setIsError(false);
            setIsSent(true);
          }
          else {
            setIsError(true);
          }
          setIsLoading(false);
        }}>
        <Form>
          <Card>
            <CardHeader>
              <h3>Confirm your email address</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Alert color="danger" isOpen={isError}>
                    Sorry, we were unable to confirm your email address. Click the button below to send a new email.
                  </Alert >
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" color="primary" size="lg" block>
                    Send new code
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p className="text-center">
                    <Link to="/login" className="link-button">
                      Return to login
                    </Link>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Formik>
    );
  }
  else if (isSent) {
    return (
      <Card>
        <CardHeader>
          <h3>Check your email</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              We have sent you an email with instructions on how to finish setting up your account.
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="text-center">
                <Link to="/" className="link-button">
                  Return to login
                </Link>
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
  else {
    return (
      <Card>
        <CardHeader>
          <h3>Email confirmed</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              Thank you for confirming your email address. You can now log in.
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p className="text-center">
                <Link to="/" className="link-button" className="link-button">
                  Return to login
                </Link>
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
