import { Alert } from 'reactstrap';
import { Link } from "react-router-dom";
import Select from 'react-select'
import { isLoadingAtom } from '../../App';
import { useEffect, useState } from 'react';
import get from '../../services/Get';
import { Button, Col, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import { useAtom } from 'jotai'
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import postSave from '../../services/PostSave';
import post from '../../services/Post';
import { ReactSelectStyles } from '../ReactSelectStyles';
import UserEdit from './UserEdit';
import UserAdd from './UserAdd';


export default function User() {
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [user, setUser] = useState(null);
  const [options, setOptions] = useState([{}]);
  const [add, setAdd] = useState(false);

  const getUser = async (id) => {
    const newUser = await get(`/api/user/${id}`);
    const c = await get(`/api/user/${id}/companies`);
    newUser.companies = c;
    setUser(newUser);
  };

  const getOptions = async () => {
    const os = await get('/api/user/options');
    setOptions(os);
  };

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      getUser(selectedOption.value);
    }
  };

  function goToAdd() {
    setAdd(true);
  }

  function cancelAdd() {
    setAdd(false);
    getOptions();
  }

  function cancelEdit() {
    setUser(null);
    getOptions();
  }

  useEffect(() => {
    getOptions();
  }, []);

  if (add) {
    return <UserAdd cancel={cancelAdd} />;
  }
  else if (user) {
    return <UserEdit user={user} cancel={cancelEdit} />;
  }
  else {
    return (
      <>
        <Row>
          <Col>
            <Select options={options} onChange={handleChange} placeholder={'select a user...'} styles={ReactSelectStyles} />
          </Col>
          <Col className="col-auto">
            <Button onClick={goToAdd} title="add a new user">add a new user</Button>
          </Col>
        </Row>
      </>
    );
  }
}
