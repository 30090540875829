const get = async function (url) {
  const response = await fetch(url, {
    method: "GET",    
    headers: {
      'Accept': 'application/json',
      'Content-type': 'application/json'
    },
    credentials: 'include',
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
export default get;