import { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import get from '../../services/Get';
import Select from 'react-select'
import { ReactSelectStyles } from '../ReactSelectStyles';
import { isLoadingAtom } from "../../App";
import { useAtom } from 'jotai'

export default function UserCompany({ user, setCompanies }) {
  const [options, setOptions] = useState([{}]);

  const getOptions = async () => {
    const os = await get(`/api/company/options`);
    setOptions(os);
  };

  const onChange = (e) => {
    if (e) {
      user.companies = e;
      setCompanies(e);
    }
    else {
      user.companies = [];
      setCompanies([]);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  if (user.globalAdmin) {
    return <Row className="mb-2"><Col><Label>allowed companies</Label><p>all - global admin</p></Col></Row>;
  }

  return (
    <>
      <Row className="mb-2">
        <Col>
          <Label>allowed companies</Label>
          <Select
            options={options}
            onChange={onChange}
            placeholder={'select a company...'}
            styles={ReactSelectStyles}
            isClearable
            isMulti
            defaultValue={user.companies}
          />
        </Col>
      </Row>
    </>
  );
}