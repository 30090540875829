import { Link } from "react-router-dom";
import { Alert, Button } from 'reactstrap';
import { isLoadingAtom, userAtom } from "../../App";
import { useAtom } from 'jotai'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import post from '../../services/Post';

export default function Home() {
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [user,] = useAtom(userAtom);

  async function spinner() {
    setIsLoading(true);
    await toast.promise(
      fetch('/api/spin'),
      {
        pending: 'Promise is pending',
        success: 'Promise resolved',
        error: 'Promise rejected'
      }
    )
    //await fetch('/api/spin');
    setIsLoading(false);
  };

  return (
    <div>
      {/*<Alert color="primary">*/}
      {/*  let's get started! try one of these*/}
      {/*</Alert>*/}

      {/*<ul>*/}
      {/*  <li><Link to="/company">company setup</Link></li>*/}
      {/*  <li><Link to="/product">product setup</Link></li>*/}
      {/*  <li><Link to="/vehicle">vehicle setup</Link></li>*/}
      {/*</ul>*/}

      {/*<Alert color="secondary">*/}
      {/*  you can also*/}
      {/*</Alert>*/}

      {/*<ul>*/}
      {/*  <li><Link to="/profile">update your profile</Link></li>*/}
      {/*  <li><Link to="/password">change your password</Link></li>*/}
      {/*</ul>*/}

      {/*<Alert color="secondary">*/}
      {/*  here are some buttons you can click*/}
      {/*</Alert>*/}

      <p>welcome to here {user.firstName} {user.lastName}</p>
      {/*<p>this is something: {data}</p>*/}
      <Button color="primary" onClick={spinner} className="me-2 mb-2">Click me</Button>
      <Button color="secondary" onClick={spinner} className="me-2 mb-2">Click me</Button>
      <Button color="info" onClick={spinner} className="me-2 mb-2">Click me</Button>
      <Button color="success" onClick={spinner} className="me-2 mb-2">Click me</Button>
      <Button color="warning" onClick={spinner} className="me-2 mb-2">Click me</Button>
      <Button color="danger" onClick={spinner} className="me-2 mb-2">Click me</Button>

      {/*<hr/>*/}

      {/*<h3>Spreadsheets!</h3>*/}
      {/*<p>Try the <Link to="/sheet">sheets</Link> they're delicious</p>*/}

      {/*<hr />*/}

      {/*<h3>upload a file</h3>*/}
      {/*<p><Link to="/upload">upload a file</Link></p>*/}

      {/*<h3>view uploaded files</h3>*/}
      {/*<p>view <Link to="/uploads">uploaded files</Link></p>*/}

      {/*<hr />*/}
      <h3>manage</h3>
      <p><Link to="/company">companies</Link></p>
      <p><Link to="/database">databases</Link></p>
      <p><Link to="/story/edit">stories</Link></p>
      {/*<p><Link to="/job/question">question</Link></p>*/}

      {/*<p><Link to="/job/questions">questions</Link></p>*/}
      {/*<p><Link to="/user">users</Link></p>*/}

      {/*<h3>normal</h3>*/}
      {/*<p><Link to="/story">tell your stories</Link></p>*/}
      {/*<p><Link to="/job/answer">answers</Link></p>*/}

      {/*<h3>do an email</h3>*/}
      {/*<p><Link onClick={() => { post("/api/user/email"); } }>send a test email</Link></p>*/}

    </div>
  );
}
