import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Field, Form, Formik } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import post from '../../services/Post';
import { useAtom } from 'jotai';
import { isLoadingAtom } from "../../App";

export default function Reset() {
  let { email, code } = useParams();
  const [isError, setIsError] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);

  const schema = Yup.object().shape({
    password: Yup.string().min(8, 'Enter your password').required('Required')
  });

  useEffect(() => {
    const confirm = async () => {
      setIsLoading(true);
      const data = await post('/api/validcode', { code: code });
      if (data) {
        setIsError(false);
      }
      else {
        setIsError(true);
      }
      setIsLoading(false);
    };
    confirm().catch(console.error);
  }, [])

  if (isError) {
    return (
      <Formik
        onSubmit={async (values) => {
          setIsLoading(true);
          const data = await post("/api/forgot", { email: values.email });
          if (data) {
            setIsSent(true);
          }
          else {
            setIsError(true);
          }
          setIsLoading(false);
        }}
      >
        <Form>
          <Card>
            <CardHeader>
              <h3>Reset your password</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Alert color="danger">
                    Unable to reset password because the code is not valid. Click the button below to send a new code.
                  </Alert >
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" color="primary" size="lg" block className="login-button">
                    Send new code
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p className="text-center">
                    <Link to="/login">
                      Return to login
                    </Link>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Formik>
    );
  }
  else if (isSent) {
    return (
      <Card>
        <CardHeader>
          <h3>Check your email</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col>
              We have sent you an email with instructions on how to reset your password.
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p className="text-center mt-4">
                <Link to="/login">
                  Return to login
                </Link>
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
  else if (isReset) {
    return (
      <Card>
        <CardHeader>
          <h3>Password changed</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col>
              Your password has been changed. You can now log in.
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p className="text-center">
                <Link to="/login">
                  Return to login
                </Link>
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
  else {
    return (
      <Formik
        initialValues={{
          email: email,
          password: '',
          code: code
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          setIsLoading(true);
          const data = await post("/api/reset", { password: values.password, code: code, email: email });
          if (data) {
            setIsReset(true);
          }
          else {
            setIsReset(false);
          }
          setIsLoading(false);
        }}
      >
        <Form>
          <Card>
            <CardHeader>
              <h3>Reset your password</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Field name="password" type="password" label="New password" component={ReactstrapInput} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" color="primary" size="lg" block className="login-button">
                    Next
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-center mt-4">
                    <Link to="/login">
                      Return to login
                    </Link>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Formik>
    );
  }
}
