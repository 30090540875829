import { Alert } from 'reactstrap';
import { Link } from "react-router-dom";
import Select from 'react-select'
import { isLoadingAtom } from '../../App';
import { useEffect, useState } from 'react';
import get from '../../services/Get';
import { Button, Col, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import { useAtom } from 'jotai'
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import postSave from '../../services/PostSave';
import post from '../../services/Post';
import { ReactSelectStyles } from '../ReactSelectStyles';
import UserCompany from './UserCompany';

export default function UserEdit({ user, cancel }) {
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [companies, setCompanies] = useState([]);

  const schema = Yup.object().shape({
    firstname: Yup.string().required('required'),
    lastname: Yup.string().required('required'),
    email: Yup.string().email('that email address doesn\'t look right!').required('required').test("email check", "this email address has already been used", async value => {
      const response = await fetch('/api/validemailuser', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ id: user.id, email: value })
      });
      let data = await response.json();
      if (data) {
        return true;
      }
      return false;
    })
  });

  const doDelete = async () => {
    setIsLoading(true);
    await post("/api/user/delete", { id: user.id });
    user.deleted = true;
    setIsLoading(false);
  }
  const doUndelete = async () => {
    setIsLoading(true);
    await post("/api/user/undelete", { id: user.id });
    user.deleted = false;
    setIsLoading(false);
  }

  const makeAdmin = async () => {
    setIsLoading(true);
    await post("/api/user/makeadmin", { id: user.id });
    user.globalAdmin = true;
    setIsLoading(false);
  }
  const removeAdmin = async () => {
    setIsLoading(true);
    await post("/api/user/removeadmin", { id: user.id });
    user.globalAdmin = false;
    setIsLoading(false);
  }

  let deleteButton = <Button type="button" color="danger" onClick={doDelete}>delete</Button>;
  if (user.deleted) {
    deleteButton = <Button type="button" color="danger" onClick={doUndelete}>undelete</Button>;
  }

  let globalAdminButton = <Button type="button" color="secondary" onClick={makeAdmin}>make admin</Button>;
  if (user.globalAdmin) {
    globalAdminButton = <Button type="button" color="secondary" onClick={removeAdmin}>remove admin</Button>;
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          firstname: user.firstName ?? "",
          lastname: user.lastName ?? "",
          email: user.email ?? "",
          id: user.id
        }}
        onSubmit={async (values) => {
          setIsLoading(true);
          await postSave("/api/user/update", {
            firstName: values.firstname,
            lastName: values.lastname,
            email: values.email,
            id: values.id,
            companies: companies
          });
          setIsLoading(false);
        }}
      >
        <Form>
          <Row>
            <Col>
              <Field name="firstname" type="text" label="firstname" component={ReactstrapInput} />
              <Field name="id" type="hidden" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field name="lastname" type="text" label="lastname" component={ReactstrapInput} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field name="email" type="text" label="email" component={ReactstrapInput} />
            </Col>
          </Row>
          <UserCompany user={user} setCompanies={setCompanies} />
          <Row>
            <Col>
              <Button type="submit" color="primary">
                save
              </Button>
              {' '}
              <Button type="button" color="secondary" onClick={cancel}>
                cancel
              </Button>
              {' '}
              {globalAdminButton}
              {' '}
              {deleteButton}
            </Col>
          </Row>
        </Form>
      </Formik>
    </>
  );

}
