import { Button, Col, Row } from 'reactstrap';
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import { useAtom, useSetAtom } from 'jotai';
import postSave from '../../services/PostSave';
import { companyAtom, isLoadingAtom } from "../../App";
import { useNavigate } from "react-router-dom";

export default function Company() {
  const setIsLoading = useSetAtom(isLoadingAtom);
  const [company, setCompany] = useAtom(companyAtom);

  const schema = Yup.object().shape({
    url: Yup.string().required('required')
      //.min(3, 'must be at least 3 characters')
      //.max(100, 'must be one hundred characters or less')
      .test("url check", "that url is taken", async value => {
        const response = await fetch('/api/company/validurl', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ id: company.id, url: value })
        });
        let data = await response.json();
        if (data) {
          return true;
        }
        return false;
      }),
    name: Yup.string().required('required')
  });
  let navigate = useNavigate();

  function cancel() {
    navigate("/home");
  }


  return (
    <>
      <Row>
        <Col><h3>your shop</h3></Col>
      </Row>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          name: company.name,
          id: company.id,
          url: company.url
        }}
        onSubmit={async (values) => {
          setIsLoading(true);
          await postSave("/api/company/profile", { name: values.name, id: values.id, url: values.url });
          company.name = values.name;
          company.url = values.url;
          setCompany(company);
          setIsLoading(false);
        }}
      >
        <Form>
          <Row>
            <Col>
              <Field name="name" type="text" label="name" component={ReactstrapInput} />
              <Field name="id" type="hidden" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field name="url" type="text" label="url" component={ReactstrapInput} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-2">
                <a href={`${window.location.origin}/s/${company.url}`} target="_blank" rel="noreferrer">
                  {`${window.location.origin}/s/${company.url}`}
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-auto">
              <Button type="submit" color="primary">
                save
              </Button>
              {' '}
              <Button type="button" color="secondary" onClick={cancel}>
                cancel
              </Button>
              {' '}
            </Col>
          </Row>
        </Form>
      </Formik>
    </>
  );
}
