import React, { useState } from 'react';
import {
  Button, Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { PersonCircle } from 'react-bootstrap-icons';
import { useAtom } from 'jotai'
import { companyAtom, isLoadingAtom, isLoggedInAtom, userAtom } from "../../App";
import post from '../../services/Post';
import { BoxArrowRight, List } from 'react-bootstrap-icons';

export default function NavMenu() {
  const [collapsed, setCollapsed] = useState(true);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const [, setIsLoggedIn] = useAtom(isLoggedInAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [company, setCompany] = useAtom(companyAtom);
  const [, setUser] = useAtom(userAtom);

  const onLink = function () {
    setCollapsed(true);
  }

  const onLinkLogout = function () {
    toggleModal();
    setCollapsed(true);
  }

  const doLogout = async e => {
    setIsLoading(true);
    setIsLoggedIn(false);
    setCompany({});
    setUser({});
    await post("/api/logout");
    setIsLoading(false);
  }

  return (
    <div>
      <header>
        <Navbar color="dark" className="fixed-top navbar-expand-sm navbar-toggleable-sm border-bottom mb-3 bg-dark" dark>
          <NavbarBrand href="/" onClick={onLink}>
            <img className="logo-private" src="logo.svg" alt="logo" />
            <span className="logo-private-title">bakesel</span>
            <div className="d-none d-sm-inline">
              {' '}<span className="text-white small">{company.name}</span>
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2"><List title="toggle menu" /></NavbarToggler>
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} to="/company" title="manage your shop" onClick={onLink}>
                  <span className="d-sm-none">
                    {company.name}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/profile" title="user profile" onClick={onLink}>
                  <span className="d-none d-sm-block">
                    <PersonCircle size={20} />
                  </span>
                  <span className="d-sm-none">
                    profile
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} onClick={onLinkLogout} title="log out">
                  <span className="d-none d-sm-block">
                    <BoxArrowRight size={20} />
                  </span>
                  <span className="d-sm-none">
                    log out
                  </span>
                </NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Navbar>
      </header>
      <Modal isOpen={modal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>log out?</ModalHeader>
        <ModalBody>
          do you want to log out?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={doLogout}>
            log out
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>
            cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
