import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row, Alert } from 'reactstrap';
import { Field, Form, Formik } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import post from '../../services/Post';
import { userAtom, isLoadingAtom, isLoggedInAtom, companyAtom } from "../../App";
import { useAtom } from 'jotai'
import get from '../../services/Get';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('that email address doesn\'t look right!').required('required'),
  password: Yup.string().min(8, 'enter your password').required('required')
});

export default function Login() {
  const [isError, setIsError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [user, setUser] = useAtom(userAtom);
  const [company, setCompany] = useAtom(companyAtom);

  const getCompany = async () => {
    setIsLoading(true);
    const c = await get(`/api/company/profile`);
    setCompany(c);
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={LoginSchema}
      onSubmit={async (values) => {
        setIsLoading(true);
        const data = await post("/api/login", { email: values.email, password: values.password });
        if (data && data.id > 0) {
          setIsError(false);
          setUser(data);
          setIsLoggedIn(true);
          getCompany();
        }
        else {
          setIsError(true);
        }
        setIsLoading(false);
      }}
    >
      <Form>
        <Card>
          <CardHeader>
            <h3>log in</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Field name="email" type="email" label="email" component={ReactstrapInput} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field name="password" type="password" label="password" component={ReactstrapInput} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Alert color="danger" isOpen={isError}>
                  there was a problem
                </Alert >
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="submit" color="primary" size="lg" block className="login-button">
                  log in
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p className="text-center">
                  <Link to="/forgot" className="link-button">
                    forgot password?
                  </Link>
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={3}></Col>
              <Col lg={6}>
                <Link to="/create" className="link-button">
                  <Button type="button" color="secondary" size="md" block>
                    create a new account
                  </Button>
                </Link>
              </Col>
              <Col lg={3}></Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </Formik>
  );
}
