
export default function ItemImage({ item }) {
  if (item && item.upload && item.upload.url) {
    return (
      <img
        src={`${item.upload.url}`}
        alt={`${item.upload.name}`}
        className="img-fluid img-item2"
      />
    );
  }
  else {
    return <div className="img-fluid img-item2"></div>;
  }
}