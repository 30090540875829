import { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import get from '../../services/Get';
import BakeEvent from './BakeEvent';
import { useAtom } from 'jotai';
import { isLoadingAtom } from '../../App';
import ItemImage from '../ItemImage';

export default function BakeEvents({ url }) {
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [options, setOptions] = useState([{}]);
  const [event, setEvent] = useState(null);

  const getEvent = async (id) => {
    if (id) {
      const db = await get(`/api/shop/event/${id}`);
      setEvent(db);
    }
  };

  const getOptions = useCallback(async () => {
    if (url) {
      const os = await get(`/api/shop/event/list/${url}`);
      setOptions(os);
    }
  }, [url]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  const handleChange = (selectedOption) => {
    setIsLoading(true);
    if (selectedOption) {
      getEvent(selectedOption.id);
    }
    setIsLoading(false);
  };

  function cancel() {
    setEvent(null);    
  }

  if (event) {
    return <BakeEvent event={event} cancel={cancel} />;
  }
  else {
    let events = <></>;
    if (options && options.length > 0) {
      events = options.map((item, index) => (
        <Row key={index}>
          <Col>
            <Card onClick={() => handleChange(item)} role="button">
              <CardBody className={item.disabled ? "bg-secondary opacity-25" : ""}>
                <Row>
                  <Col className="col-auto">
                    <div className="container-img-item2">
                      <ItemImage item={item} />
                    </div>
                  </Col>
                  <Col>
                    <CardTitle tag="h5" className={item.disabled ? "text-muted" : ""}>
                      <div>{new Date(item.startTicks).toLocaleDateString('en-US')}</div>
                      <div>{new Date(item.startTicks).toLocaleTimeString('en-US')}</div>
                      <div>{item.name}</div>
                    </CardTitle>
                    <CardText className={item.disabled ? "text-muted" : ""}>
                      {item.description}
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row >
      ));
    }
    return (
      <>
        <Row>
          <Col>
            <h3>events</h3>
          </Col>
        </Row>
        {events}
      </>
    );
  }
}
