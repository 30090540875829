import { Button, Col, Input, Row } from 'reactstrap';
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import { useAtom } from 'jotai';
import postSave from '../../services/PostSave';
import { isLoadingAtom } from "../../App";
import post from '../../services/Post';
import { useState } from 'react';
import { X } from 'react-bootstrap-icons';
import FieldTextareaAutosize from '../FieldTextareaAutosize';
import ItemEditImage from '../ItemEditImage';

export default function ProductEdit({ product, cancel, setProduct }) {
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [upload, setUpload] = useState(product.upload);

  const schema = Yup.object().shape({
    name: Yup.string().required('required')
  });
  const doDelete = async () => {
    setIsLoading(true);
    await post("/api/product/delete", { id: product.id });
    product.deleted = true;
    setIsLoading(false);
  }
  const doUndelete = async () => {
    setIsLoading(true);
    await post("/api/product/undelete", { id: product.id });
    product.deleted = false;
    setIsLoading(false);
  }

  const deleteImage = async () => {
    setIsLoading(true);
    await post("/api/product/deleteimage", { id: product.id, uploadId: upload.id });
    setUpload(null);
    setIsLoading(false);
  }

  const handleFileChange = async (event) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('fileName', event.target.files[0].name);
    let response = await fetch(`/api/product/upload/${product.id}`, {
      method: 'POST',
      body: formData
    });
    if (response.ok) {
      const u = await response.json();
      setUpload(u);
    }
    setIsLoading(false);
  }

  let deleteButton = <Button type="button" color="danger" onClick={doDelete}>delete</Button>;
  if (product.deleted) {
    deleteButton = <Button type="button" color="danger" onClick={doUndelete}>undelete</Button>;
  }

  return (
    <div className="pb-2">
      <Row>
        <Col><h3>manage product</h3></Col>
      </Row>
      <Row>
        <Col>
          <ItemEditImage item={product} upload={upload} deleteImage={deleteImage} handleFileChange={handleFileChange} />
        </Col>
      </Row>
      <Formik
        validationSchema={schema}
        initialValues={{
          id: product.id,
          name: product.name,
          description: product.description,
          price: product.price
        }}
        onSubmit={async (values) => {
          setIsLoading(true);
          await postSave("/api/product/edit", {
            id: values.id,
            name: values.name,
            description: values.description,
            price: values.price
          });
          setIsLoading(false);
        }}
      >
        <Form>
          <Row>
            <Col>
              <Field name="name" type="text" label="name" component={ReactstrapInput} />
              <Field name="id" type="hidden" />
            </Col>
          </Row>
          <Row>
            <Col>
              <FieldTextareaAutosize
                name="description"
                label="description"
                minRows="1"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field name="price" type="number" label="price" component={ReactstrapInput} />
            </Col>
          </Row>
          <Row>
            <Col className="col-auto">
              <Button type="submit" color="primary">
                save
              </Button>
              {' '}
              <Button type="button" color="secondary" onClick={cancel}>
                cancel
              </Button>
              {' '}
              {deleteButton}
            </Col>
          </Row>
        </Form>
      </Formik>
    </div>
  );
}
