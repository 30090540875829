import { Button, Col, Input, Row } from 'reactstrap';
import { X } from 'react-bootstrap-icons';

export default function ItemEditImage({ item, upload, deleteImage, handleFileChange }) {
  if (upload && upload.url) {
    return (
      <>
        <Row>
          <Col>
            <label className="mb-2">image</label>
          </Col>
        </Row>
        <Row>
          <Col className="col-auto">
            <div className="container-img-item mb-2">
              <img
                src={`${upload.url}`}
                alt={`${item.name}`}
                className="img-fluid img-item" />
            </div>
          </Col>
          <Col>
            <Button
              onClick={deleteImage}
              color="danger"
              aria-label="delete image"
              title="delete image"
            >remove image</Button>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row>
          <Col>
            <label className="mb-2">image</label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              id="exampleFile"
              name="file"
              type="file"
              onChange={handleFileChange}
            />
          </Col>
        </Row>
      </>
    );
  }
}