import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Field, Form, Formik } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import post from '../../services/Post';
import { useAtom } from 'jotai';
import { isLoadingAtom } from "../../App";

export default function Forgot() {
  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);

  const ForgotSchema = Yup.object().shape({
    email: Yup.string().email('that email address doesn\'t look right!').required('required'),
  });

  if (isSent) {
    return (
      <Card>
        <CardHeader>
          <h3>check your email</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              we sent you an email with instructions on how to reset your password
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p className="text-center">
                <Link to="/" className="link-button">
                  return to log in
                </Link>
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
  else {
    return (
      <Formik
        initialValues={{
          email: ''
        }}
        validationSchema={ForgotSchema}
        onSubmit={async (values) => {
          setIsLoading(true);
          const data = await post("/api/forgot", { email: values.email });
          if (data) {
            setIsSent(true);
            setIsError(false);
          }
          else {
            setIsSent(false);
            setIsError(true);
          }
          setIsLoading(false);
        }}
      >
        <Form>
          <Card>
            <CardHeader>
              <h3>forgot password?</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Field name="email" type="email" label="email" component={ReactstrapInput} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Alert color="danger" isOpen={isError}>
                    there was a problem
                  </Alert >
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" color="primary" size="lg" block className="login-button">
                    next
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p className="text-center">
                    <Link to="/" className="link-button">
                      return to log in
                    </Link>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Formik>
    );
  }
}
