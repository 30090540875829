import { Link } from "react-router-dom";
import { Col, Row } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';

export default function Home() {

  return <>
    <Row>
      <Col className="col-auto">
        <p><Link to="/shop">manage your shop</Link></p>
        <p><Link to="/products">manage your products</Link></p>
        <p><Link to="/events">manage your events</Link></p>
      </Col>
    </Row>
  </>;
}
