import { Navigate, Route, Routes } from "react-router-dom";
import { Container } from 'reactstrap';
import NavMenu from "./components/shop/NavMenu";
import Home from "./components/shop/Home";
import { useParams } from 'react-router-dom'
import { atom, useAtom } from 'jotai'
import BakeEvents from "./components/shop/BakeEvents";

export default function AppShop({ url }) {

  const routes = (
    <Routes>
      <Route path="/s/:url" element={<BakeEvents url={url} />} />
    </Routes>
  );

  return (
    <div>
      <NavMenu url={url} />      
      <Container fluid className="container-private">        
        {routes}
      </Container>
    </div>
  );
}