import toast from 'react-hot-toast';

const post = async function (url, data) {  
  toast.loading('');
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      'Accept': 'application/json',
      'Content-type': 'application/json'
    },
    credentials: 'include',
  });
  toast.remove();
  if (response.ok) {
    try {
      var r = await response.json();
      return r;
    }
    catch (e) {      
      return null;
    }
  }
  return null;
}
export default post;