import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik-mvmorten";
import * as Yup from 'yup';
import { useAtom } from 'jotai';
import postSave from '../../services/PostSave';
import { companyAtom, isLoadingAtom } from "../../App";
import post from '../../services/Post';
import { useCallback, useEffect, useState } from 'react';
import { toDateOnly, toDateTime, toTimeOnly } from '../../services/DateUtils';
import FieldTextareaAutosize from '../FieldTextareaAutosize';
import ItemEditImage from '../ItemEditImage';
import get from '../../services/Get';


export default function BakeEventEdit({ bakeEvent, cancel, setBakeEvent }) {
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [upload, setUpload] = useState(bakeEvent.upload);
  const deleteImage = async () => {
    setIsLoading(true);
    await post("/api/bakeevent/deleteimage", { id: bakeEvent.id, uploadId: upload.id });
    setUpload(null);
    setIsLoading(false);
  };
  const [company,] = useAtom(companyAtom);
  const [options, setOptions] = useState([{}]);

  const handleFileChange = async (event) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('fileName', event.target.files[0].name);
    let response = await fetch(`/api/bakeevent/upload/${bakeEvent.id}`, {
      method: 'POST',
      body: formData
    });
    if (response.ok) {
      const u = await response.json();
      setUpload(u);
    }
    setIsLoading(false);
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('required')
  });
  const doDelete = async () => {
    setIsLoading(true);
    await post("/api/bakeevent/delete", { id: bakeEvent.id });
    bakeEvent.deleted = true;
    setIsLoading(false);
  };
  const doUndelete = async () => {
    setIsLoading(true);
    await post("/api/bakeevent/undelete", { id: bakeEvent.id });
    bakeEvent.deleted = false;
    setIsLoading(false);
  };
  const getOptions = useCallback(async () => {
    if (company && company.id > 0) {
      const os = await get(`/api/product/listactive/${company.id}`);
      setOptions(os);
      //setInitial(false);
    }
  }, [company]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  let deleteButton = <Button type="button" color="danger" onClick={doDelete}>delete</Button>;
  if (bakeEvent.deleted) {
    deleteButton = <Button type="button" color="danger" onClick={doUndelete}>undelete</Button>;
  }

  let products = <></>;
  if (bakeEvent.products && bakeEvent.products.length > 0) {
    products = bakeEvent.products.map((item, index) => (
      <Row key={index}>
        <Col>
          <FormGroup
            check
            inline
          >
            <Field
              label={item.name}
              value={'' + item.productId} // this has to be a string: https://github.com/jaredpalmer/formik/issues/2775
              name="productIds"
              type="checkbox"
              component={ReactstrapInput}
            />
          </FormGroup>
        </Col>
      </Row>
    ));
  }

  return (
    <div className="pb-2">
      <Row>
        <Col><h3>manage event</h3></Col>
      </Row>
      <Row>
        <Col>
          <ItemEditImage item={bakeEvent} upload={upload} deleteImage={deleteImage} handleFileChange={handleFileChange} />
        </Col>
      </Row>
      <Formik
        validationSchema={schema}
        initialValues={{
          name: bakeEvent.name,
          description: bakeEvent.description,
          id: bakeEvent.id,
          startDate: toDateOnly(bakeEvent.startTicks),
          startTime: toTimeOnly(bakeEvent.startTicks),
          endDate: toDateOnly(bakeEvent.endTicks),
          endTime: toTimeOnly(bakeEvent.endTicks)          
        }}
        onSubmit={async (values) => {
          setIsLoading(true);
          await postSave("/api/bakeevent/edit", {
            name: values.name,
            id: values.id,
            description: values.description,
            start: toDateTime(values.startDate, values.startTime),
            end: toDateTime(values.endDate, values.endTime),
            productIds: values.productIds
          });
          setIsLoading(false);
        }}
      >
        <Form>
          <Row>
            <Col>
              <Field name="name" type="text" label="name" component={ReactstrapInput} />
              <Field name="id" type="hidden" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name="startDate"
                type="date"
                label="start date"
                component={ReactstrapInput} />
            </Col>
            <Col>
              <Field
                name="startTime"
                type="time"
                label="start time"
                component={ReactstrapInput} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name="endDate"
                type="date"
                label="end date"
                component={ReactstrapInput} />
            </Col>
            <Col>
              <Field
                name="endTime"
                type="time"
                label="end time"
                component={ReactstrapInput} />
            </Col>
          </Row>
          <Row>
            <Col>
              <FieldTextareaAutosize
                name="description"
                label="description"
                minRows="1" />
            </Col>
          </Row>
          <Row>
            <Col>
              <div role="group" aria-labelledby="checkbox-group">
                {products}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-auto">
              <Button type="submit" color="primary">
                save
              </Button>
              {' '}
              <Button type="button" color="secondary" onClick={cancel}>
                cancel
              </Button>
              {' '}
              {deleteButton}
            </Col>
          </Row>
        </Form>
      </Formik>
    </div>
  );
}
